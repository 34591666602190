import { Stack, InputLabel, Typography } from "@mui/material";
import { t } from "i18next";
import DateOfBirthInput, {
  DOBFormData,
} from "../../../../../../../Common/components/DateOfBirthInput";

interface MemberOnboardingDateOfBirthInputProps {
  onChange: (value: string) => void;
  value: string;
}

export default function MemberOnboardingDateOfBirthInput({
  onChange,
  value,
}: MemberOnboardingDateOfBirthInputProps) {
  function onDateOfBirthInput(
    data: DOBFormData,
    validatedTotalDate: boolean
  ): void {
    if (validatedTotalDate) {
      const dateOfBirth = `${data.year}-${data.month.padStart(
        2,
        "0"
      )}-${data.day.padStart(2, "0")}`;
      onChange(dateOfBirth);
    } else {
      onChange("");
    }
  }

  return (
    <Stack spacing={1}>
      <InputLabel>
        <Typography color="neutral.700" variant="body2" fontWeight={300}>
          {t("DateOfBirthInput.label")}
        </Typography>
      </InputLabel>
      <DateOfBirthInput
        onChange={onDateOfBirthInput}
        value={value}
        placeholder={{
          day: "DD",
          month: "MM",
          year: "YYYY",
        }}
        showLabel={false}
      />
    </Stack>
  );
}
