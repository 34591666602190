import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import { AnalyticsEvent, trackFormEvent } from "@/services/analytics-adapter";
import {
  getMember,
  IdentityDocumentTypeValue,
  submitRetailUserDetails,
  SubmitUserDetailsRequest,
} from "@/services/core-api-adapter";
import { useGlobalStore } from "@/store";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import MemberOnboardingForm, {
  UserOnboardingDetails,
} from "./components/MemberOnboardingForm";

interface CollectMemberOnboardingDetailsProps {
  onCompletedFlow?: () => void;
  data?: {
    userDetails: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      sexAtBirth: string;
    };
  };
}

export default function CollectMemberOnboardingDetails({
  onCompletedFlow,
  data,
}: CollectMemberOnboardingDetailsProps) {
  const { state, dispatch } = useGlobalStore();

  const [isFormError, setIsFormError] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [shouldCollectAdditionalDetails, setShouldCollectAdditionalDetails] =
    useState<boolean>(false);
  const [existingUserDetails, setExistingUserDetails] =
    useState<UserOnboardingDetails | null>(null);

  useEffect(() => {
    const existingUserData: any = {};

    if (data?.userDetails?.firstName) {
      existingUserData.firstName = data.userDetails.firstName;
    }

    if (data?.userDetails?.lastName) {
      existingUserData.lastName = data.userDetails.lastName;
    }

    if (data?.userDetails?.dateOfBirth) {
      existingUserData.dateOfBirth = data.userDetails.dateOfBirth;
    }

    if (data?.userDetails?.sexAtBirth) {
      existingUserData.sexAtBirth = data.userDetails.sexAtBirth;
    }

    getMember().then((member: any) => {
      setShouldCollectAdditionalDetails(
        member.identityDocumentType !== IdentityDocumentTypeValue.ID_NUMBER
      );
      existingUserData.phoneNumber = {
        countryCode: state.countryCode,
        globalSubscriberNumber: member.mobileNumber,
      };
      setExistingUserDetails(existingUserData as UserOnboardingDetails);
    });
  }, [
    data?.userDetails?.dateOfBirth,
    data?.userDetails?.firstName,
    data?.userDetails?.lastName,
    data?.userDetails?.sexAtBirth,
    state.countryCode,
  ]);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Member onboarding details",
    });
  }, []);

  async function onSubmit(value: SubmitUserDetailsRequest) {
    setIsSubmittingForm(true);

    try {
      await submitRetailUserDetails(value);
      const data = await getMember();
      dispatch({
        type: "SET_CURRENT_USER",
        payload: data,
      });

      setIsSubmittingForm(false);
      onDone();
    } catch (error) {
      setIsSubmittingForm(false);
      console.error("An error occurred:", error);
      onError();
    }
  }

  function onDone() {
    if (onCompletedFlow) {
      setIsFormError(false);
      onCompletedFlow();

      trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
        formName: "Member onboarding details",
      });
    }
  }

  function onError() {
    setIsFormError(true);
  }

  return (
    <Stack height="100%" justifyContent="flex-end">
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <>
          {existingUserDetails && (
            <MemberOnboardingForm
              onFormSubmit={onSubmit}
              isFormError={isFormError}
              shouldCollectAdditionalDetails={shouldCollectAdditionalDetails}
              data={existingUserDetails}
            />
          )}
        </>
      )}
    </Stack>
  );
}
