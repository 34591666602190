import { Box, Typography } from "@mui/material";
import CollectMemberOnboardingDetails from ".";

export default function CollectMemberOnboardingDetailsExamples() {
  function onCompletedFlow() {
    console.log("onCompletedFlow");
  }

  const existingUserDetails =
    '{"userDetails": {"firstName":"Lesedi","lastName":"Persona","dateOfBirth":null,"sexAtBirth":null}}';
  return (
    <>
      <Box sx={{ p: 2 }}>
        <CollectMemberOnboardingDetails onCompletedFlow={onCompletedFlow} />
        <Typography>Existing user details</Typography>
        <CollectMemberOnboardingDetails
          data={JSON.parse(existingUserDetails)}
          onCompletedFlow={onCompletedFlow}
        />
      </Box>
    </>
  );
}
